import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";

import IAModalSelectItems from "../../components/IA/IAModalSelectItems";
import CollectInformation from "../../components/IA/CollectInformation";
import ReviewModal from "../../components/IA/ReviewModal";
import LoadingIA from "../../components/IA/LoadingIA";
import { IARequests } from "../../services/apis/requests/ia";
import { IProductToTable } from "../products/product.context";
import { useProductContext } from "../products";

interface IAContextType {
  IAMode: boolean;
  setIAMode: React.Dispatch<React.SetStateAction<boolean>>;
  rowSelectedToIA: number[];
  setRowSelectedToIA: React.Dispatch<React.SetStateAction<number[]>>;
  parseInputToList: (input: string) => number[];
  setModalInformationOpened: React.Dispatch<React.SetStateAction<boolean>>;
  convertData: ({ data }: { data: string | number[] }) => void;
  enrichmentType: string;
  setEnrichmentType: React.Dispatch<React.SetStateAction<string>>;
  columnsSelecteds: string[];
  setColumnsSelecteds: React.Dispatch<React.SetStateAction<string[]>>;
  instructions: string;
  setInstructions: React.Dispatch<React.SetStateAction<string>>;
  startEnrichment: ({
    currentProducts,
  }: {
    currentProducts: IProductToTable[];
  }) => Promise<void>;
  progress: number | null;
  reviewMode: boolean;
  setReviewMode: React.Dispatch<React.SetStateAction<boolean>>;
  setTextSelectedToIa: React.Dispatch<React.SetStateAction<string>>;
  productsIA: IProductToTable[];
  approvedIdsList: string[];
  setApprovedIdsList: React.Dispatch<React.SetStateAction<string[]>>;
  disapprovedIdsList: string[];
  setDisapprovedIdsList: React.Dispatch<React.SetStateAction<string[]>>;
  updateIdList: ({
    id,
    listType,
  }: {
    id: string;
    listType: "approved" | "disapproved";
  }) => void;
  socketRef: React.MutableRefObject<Socket | null>;
  finishEnrichment: () => Promise<void>;
  approveAll: boolean;
  setApproveAll: React.Dispatch<React.SetStateAction<boolean>>;
  disapproveAll: boolean;
  setDisapproveAll: React.Dispatch<React.SetStateAction<boolean>>;
  templateId: string | undefined;
}

const IAContext = createContext<IAContextType | undefined>(undefined);
interface IAContextProviderProps {
  children: ReactNode;
}

export const IAContextProvider: React.FC<IAContextProviderProps> = ({
  children,
}) => {
  const { id: templateId } = useParams<{ id: string }>();
  const { products, handleRedirectAndGetProducts } = useProductContext();

  const [productsIA, setProductsIA] = useState<IProductToTable[]>([]);

  useEffect(() => {
    setProductsIA(products.map((item) => ({ ...item, "000000": "" })) as any);
  }, [products]);

  const [enrichmentType, setEnrichmentType] = useState("");
  const [IAMode, setIAMode] = useState(false);
  const [modalInformationOpened, setModalInformationOpened] = useState(false);
  const [rowSelectedToIA, setRowSelectedToIA] = useState<number[]>([]);
  const [textSelectedToIa, setTextSelectedToIa] = useState("");
  const [instructions, setInstructions] = useState("");
  const [columnsSelecteds, setColumnsSelecteds] = useState<string[]>([]);
  const [loadingIA, setLoadingIA] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [approvedIdsList, setApprovedIdsList] = useState<string[]>([]);
  const [disapprovedIdsList, setDisapprovedIdsList] = useState<string[]>([]);
  const [approveAll, setApproveAll] = useState(false);
  const [disapproveAll, setDisapproveAll] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [currentItemProgress, setCurrentItemProgress] = useState<number | null>(
    null,
  );
  const startValueRef = useRef<number | null>(null);
  const endValue = 0;
  const socketRef = useRef<Socket | null>(null);

  const finishEnrichment = async (): Promise<void> => {
    if (templateId) {
      const body = { templateId };
      await IARequests.postFinishEnrichment({ data: body });
      setEnrichmentType("");
      setIAMode(false);
      setModalInformationOpened(false);
      setRowSelectedToIA([]);
      setTextSelectedToIa("");
      setInstructions("");
      setColumnsSelecteds([]);
      setLoadingIA(false);
      setReviewMode(false);
      setApprovedIdsList([]);
      setDisapprovedIdsList([]);
      setApproveAll(false);
      setDisapproveAll(false);
      handleRedirectAndGetProducts(templateId);
      setProgress(null);
      setCurrentItemProgress(null);
      setProductsIA(products.map((item) => ({ ...item, "000000": "" })) as any);
    }
  };

  const updateIdList = ({
    id,
    listType,
  }: {
    id: string;
    listType: "approved" | "disapproved";
  }): void => {
    if (listType === "approved") {
      setDisapproveAll(false);
      setApproveAll(false);
      setApprovedIdsList((prevApprovedList) => {
        setDisapprovedIdsList((prevDisapprovedList) => {
          if (prevDisapprovedList.includes(id)) {
            return prevDisapprovedList.filter((item) => item !== id);
          }
          return prevDisapprovedList;
        });

        if (prevApprovedList.includes(id)) {
          return prevApprovedList.filter((item) => item !== id);
        }
        return [...prevApprovedList, id];
      });
    } else if (listType === "disapproved") {
      setApproveAll(false);
      setDisapproveAll(false);
      setDisapprovedIdsList((prevDisapprovedList) => {
        setApprovedIdsList((prevApprovedList) => {
          if (prevApprovedList.includes(id)) {
            return prevApprovedList.filter((item) => item !== id);
          }
          return prevApprovedList;
        });

        if (prevDisapprovedList.includes(id)) {
          return prevDisapprovedList.filter((item) => item !== id);
        }
        return [...prevDisapprovedList, id];
      });
    }
  };

  const parseInputToList = useCallback((input: string): number[] => {
    if (!input.trim()) {
      setRowSelectedToIA([]);
      return [];
    }

    const ranges = input.split(",").map((item) => item.trim());
    const result: number[] = [];

    ranges.forEach((range) => {
      if (range.includes("-")) {
        const [start, end] = range.split("-").map(Number);
        // eslint-disable-next-line no-plusplus
        for (let i = start; i <= end; i++) {
          result.push(i - 1);
        }
      } else {
        result.push(Number(range) - 1);
      }
    });

    setRowSelectedToIA(result);

    return result;
  }, []);

  const convertListToInput = useCallback((list: number[]): string => {
    if (list.length === 0) {
      return "";
    }

    const sortedList = [...list].sort((a, b) => a - b);
    const ranges: string[] = [];
    let rangeStart = sortedList[0];
    let rangeEnd = sortedList[0];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < sortedList.length; i++) {
      if (sortedList[i] === rangeEnd + 1) {
        rangeEnd = sortedList[i];
      } else {
        ranges.push(
          rangeStart === rangeEnd
            ? `${rangeStart + 1}`
            : `${rangeStart + 1}-${rangeEnd + 1}`,
        );
        rangeStart = sortedList[i];
        rangeEnd = sortedList[i];
      }
    }

    ranges.push(
      rangeStart === rangeEnd
        ? `${rangeStart + 1}`
        : `${rangeStart + 1}-${rangeEnd + 1}`,
    );
    const intervalString = ranges.join(", ");
    setTextSelectedToIa(intervalString);
    return intervalString;
  }, []);

  const convertData = useCallback(
    ({ data }: { data: string | number[] }): void => {
      if (typeof data === "string") {
        parseInputToList(data);
      } else {
        convertListToInput(data);
      }
    },
    [convertListToInput, parseInputToList],
  );

  const startEnrichment = async ({
    currentProducts,
  }: {
    currentProducts: IProductToTable[];
  }): Promise<void> => {
    const producstsIds = rowSelectedToIA.map((row) => {
      return currentProducts[row].id;
    });
    const body = {
      type: "description",
      action: "update_description",
      templateId: templateId!,
      fields: columnsSelecteds,
      instructions,
      productsIds: producstsIds,
      length: enrichmentType,
    };

    await IARequests.postEnrichment({ data: body });
  };

  const updateProduct = (productUpdate: {
    productId: string;
    value: string;
  }): void => {
    // @ts-ignore
    setProductsIA((prevProducts) =>
      prevProducts.map((product) => {
        if (product.id === productUpdate.productId) {
          return {
            ...product,
            "000000": productUpdate.value,
          };
        }
        return product;
      }),
    );
  };

  useEffect(() => {
    const socket = io("wss://test-api.listme.io/progress");
    socketRef.current = socket;
    socket.on("ai_progress", (data) => {
      if (startValueRef.current === null && data) {
        startValueRef.current = data;
      }
      if (startValueRef.current !== null) {
        const progressPercentage =
          ((startValueRef.current - data) /
            (startValueRef.current - endValue)) *
          100;
        setCurrentItemProgress(data);
        setProgress(progressPercentage);
        if (progressPercentage === 100) {
          setTimeout(() => {
            setLoadingIA(false);
            setCurrentItemProgress(0);
            setProgress(0);
          }, 1500);
        }
      }
    });
    socket.on("ai_generated_value", (data) => {
      updateProduct(data);
    });

    socket.on("connect_error", (error) => {
      console.error("Socket.IO connection error:", error);
    });
    socket.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed");
      console.log("Reason:", reason);
    });

    return () => {
      socket.disconnect();
    };
  }, [startValueRef, templateId]);

  const handleDisconnect = () => {
    if (socketRef.current) {
      socketRef.current.emit("ai_progress", { templateId });
      socketRef.current.disconnect();
      setLoadingIA(false);
      console.log("Socket.IO connection manually closed");
    }
  };

  const values = {
    IAMode,
    setIAMode,
    rowSelectedToIA,
    setRowSelectedToIA,
    parseInputToList,
    setModalInformationOpened,
    convertData,
    enrichmentType,
    setEnrichmentType,
    setColumnsSelecteds,
    columnsSelecteds,
    instructions,
    setInstructions,
    startEnrichment,
    progress,
    reviewMode,
    setReviewMode,
    setTextSelectedToIa,
    productsIA,
    approvedIdsList,
    setApprovedIdsList,
    disapprovedIdsList,
    setDisapprovedIdsList,
    updateIdList,
    socketRef,
    finishEnrichment,
    approveAll,
    setApproveAll,
    disapproveAll,
    setDisapproveAll,
    templateId,
  };

  return (
    <IAContext.Provider value={values}>
      {children}
      {IAMode && (
        <IAModalSelectItems
          convertData={convertData}
          setTextSelectedToIa={setTextSelectedToIa}
          textSelectedToIa={textSelectedToIa}
          setIAMode={setIAMode}
          setModalInformationOpened={setModalInformationOpened}
        />
      )}
      {modalInformationOpened && (
        <CollectInformation
          setModalInformationOpened={setModalInformationOpened}
          setSelecteds={setColumnsSelecteds}
          selecteds={columnsSelecteds}
          setInstructions={setInstructions}
          setLoadingIA={setLoadingIA}
          setIAMode={setIAMode}
          setReviewMode={setReviewMode}
          onFinish={startEnrichment}
        />
      )}
      {reviewMode && <ReviewModal />}
      {loadingIA && progress && (
        <LoadingIA
          progress={progress}
          total={startValueRef.current}
          current={currentItemProgress}
          handleDisconnect={handleDisconnect}
        />
      )}
      {/* <ReviewPopUp /> */}
    </IAContext.Provider>
  );
};

export const useIAContext = (): IAContextType => {
  const context = useContext(IAContext);
  if (!context) {
    throw new Error("useIAContext must be used within a IAContextProvider");
  }
  return context;
};
